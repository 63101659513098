@tailwind base;
@layer base {
  h1 {
    @apply text-3xl;
    @apply font-bold;
  }
  h2 {
    @apply text-2xl;
    @apply font-bold;
  }
  h3 {
    @apply text-4xl;
    @apply font-bold;
  }
  h4 {
    @apply text-3xl;
    @apply font-bold;
  }
  h5 {
    @apply text-2xl;
    @apply font-bold;
  }
  h6 {
    @apply text-xl;
    @apply font-bold;
  }
}
@tailwind components;
@tailwind utilities;

body {
  font-family: 'exo', sans-serif;
  @apply tracking-wider;
  @apply leading-7;
  @apply text-gray-900;
  overflow: overlay;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
b,
li {
  @apply text-gray-600;
}

.active .doc-nav-link {
  @apply border-l-4;
  @apply border-green-800;
  @apply text-gray-900;
  margin-left: -3px;
}

::-webkit-scrollbar {
  @apply w-2;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-gray-500;
  @apply rounded-lg;
}

.rdw-dropdown-wrapper span {
  @apply text-gray-900;
}

.rdw-dropdown-optionwrapper li {
  @apply text-gray-900;
}

.rdw-colorpicker-modal span {
  @apply text-gray-900;
}

.rdw-image-modal span,
input {
  @apply text-gray-900;
}

.rdw-image-modal input {
  @apply px-2;
  @apply py-4;
  @apply border;
  @apply border-gray-600;
}

.rdw-embedded-modal {
  @apply h-auto !important;
}

.rdw-embedded-modal span {
  @apply text-gray-900;
}

.rdw-embedded-modal input {
  @apply px-2;
  @apply py-4;
  @apply border;
  @apply border-gray-600;
}
